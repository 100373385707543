import { Point } from "geojson";
import { EdgeDevice } from "./EdgeDevice";

export enum LprCameraType {
  spotgenius_lpr = "spotgenius_lpr",
  hikvision_lpr = "hikvision_lpr",
  tagmaster_lpr = "tagmaster_lpr",
  survision_lpr = "survision_lpr",
  eagleeye_lpr = "eagleeye_lpr",
  platerecognizer_lpr = "platerecognizer_lpr",
  flock_lpr = "flock_lpr",
}

export enum LprCameraDirection {
  lpr_direction_entry = "lpr_direction_entry",
  lpr_direction_exit = "lpr_direction_exit",
}

interface CameraBase {
  name: string;
  stream_url: string;
  public_stream_url: string | null;
  gps_coordinates: Point | string;
  direction_point?: Point;
  calibration_params?: Record<string, string>;

  sampling_interval_secs?: number;
  refined_frame_path?: string;
  is_inference_processing_method: string;
  server_id: string | null;
  edge_device_id: string | null;
  lpr_edge_device_id: string | null;

  is_refinement_working?: boolean;

  camera_offline_alert_delay_threshold_minutes: number | null;
  comment: string | null;

  is_lpr_camera_type: LprCameraType | null;
  crop_anpr_matching_image_using_inference: boolean;
  is_camera_garbled_image_detection_feature_enabled: boolean;

  fov_direction?: number;
  lpr_direction: LprCameraDirection | null;
  lpr_url: string | null;
  is_lpr_status_check_enabled: boolean;
  is_direction_detected_from_lpr: boolean;
  save_lpr_events_only_for_vehicle_facing_direction: string;
  save_exit_lpr_events_only_for_vehicle_facing_direction: string;
  is_lot_boundary_lpr_camera: boolean;
  untracked_zone_id: number | null;
  adjacent_zone_id: number | null;
  lpr_zone_id: number | null;

  level_id: number | null;

  flock_lpr_external_network_id?: string | null;
  flock_lpr_external_device_id: string | null;

  lpr_log: string | null;
}

export interface CameraCreate extends CameraBase {
  parking_lot_id: number;
}

export interface CameraUpdate extends CameraBase {
  id: number;
  parking_lot_id: number;
  camera_map?: string;
  is_active: boolean;
}

export interface Camera extends CameraBase {
  id: number;
  last_activated_at: string;
  is_active: boolean;
  is_stream_unreadable: boolean;
  is_lpr_unreadable: boolean;
  map_updated_alert_id?: number;
  has_pending_alert?: boolean;
  reference_frame_path_url: string | null;
  count_vehicles_only_in_roi: any | null;
  count_vehicles_crossing_line_points: any | null;
  untracked_zone_id: number | null;
  counting_zone_id: number | null;
  server_name: string | null;
  edge_device_name: string | null;
  lpr_edge_device_name: string | null;
  edge_device: EdgeDevice | null;
  updated_at: string | null;

  is_starting_up: boolean;
  parking_lot_id: number;
}

export interface CameraProcessingHistory {
  id: number;
  camera_id: number;
  number_of_frames_read: number;
  number_of_resets: number;
  number_of_bad_frames: number;

  frame_resolution_width: number | null;
  frame_resolution_height: number | null;
  fps: number | null;

  since_timestamp: string;
  upto_timestamp: string;
}

export interface CameraDetails {
  camera_id: number;

  model_number: string | null;
  manufacturer: string | null;

  ip_address: string | null;
  mac_address: string | null;
  is_nvr_connected: boolean | null;
  nvr_channel: number | null;
  nvr_port: number | null;
  rtsp_port: number | null;
  http_port: number | null;
  admin_username: string | null;
  admin_password: string | null;

  is_wifi_connected: boolean | null;
  wifi_access_point_name: string | null;

  resolution: string | null;
  frame_rate: number | null;
  bitrate_type: string | null;
  max_bitrate: number | null;
  encoding_method: string | null;
  iframe_interval: number | null;
}

export interface CamerasList {
  id: number;
  name: string;
  is_lpr_camera_type: LprCameraType | null;
}
