





























































































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapActions } from "vuex";
import { Tenant, PrivateLotUser } from "@/api/models";
import TenantForm from "@/components/forms/TenantForm.vue";
import PrivateLotUserForm from "@/components/forms/PrivateLotUserForm.vue";

interface tenants {
  [id: number]: string;
}

export default Vue.extend({
  name: "AddDeleteUsersPrivateLot",
  components: { TenantForm, PrivateLotUserForm },
  props: {
    lotId: {
      required: true,
      type: [Number, String],
    },
  },

  data() {
    return {
      isLoading: false,
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Add or Delete Users to Private Lot",
          disabled: true,
        },
      ],
      tenants: {
        data: [] as Array<Tenant>,
        selected: null as Tenant | null,
        headers: [
          { text: "Name", value: "name" },
          { text: "Max Parking allowed", value: "max_occupied_spots" },
          { text: "Actions", value: "actions" },
        ],
        total: 0,
        page: 1,
        itemsPerPage: 5,
        showTenantForm: false,
      },
      privateLotUser: {
        data: [] as Array<PrivateLotUser>,
        selected: null as PrivateLotUser | null,
        headers: [
          { text: "Name", value: "name" },
          { text: "Email", value: "email" },
          { text: "Contact number", value: "contact_number" },
          { text: "Tenant", value: "tenant" },
          { text: "Vehicle/License Plate", value: "vehicle_details" },
          { text: "Actions", value: "actions" },
        ],
        total: 0,
        page: 1,
        itemsPerPage: 10,
        showUserForm: false,
      },
      editTenantData: null as Tenant | null,
      editUserData: null as PrivateLotUser | null,
      tenantNames: {} as tenants,
      showTenantDeleteDialog: false,
      showUserDeleteDialog: false,
    };
  },

  mounted() {
    this.getLotData(Number(this.lotId));
    this.getAllData();
  },

  computed: {
    allLicensePlates(): Array<string> {
      let license_plate_numbers: Array<string> = [];
      let vehicles = this.privateLotUser.data
        .map((user: PrivateLotUser) => user.vehicle_details)
        .flat();
      vehicles.forEach((vehicle) => {
        if (vehicle?.license_plate_number) {
          license_plate_numbers.push(vehicle.license_plate_number);
        }
      });
      return license_plate_numbers;
    },
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);
      }
    },

    async getAllData() {
      this.isLoading = true;
      let tenants = await api.getAllTenants(Number(this.lotId));
      if (tenants) {
        this.tenants.data = tenants;
        this.tenants.total = tenants.length;
        for (let tenant of tenants) {
          this.tenantNames[tenant.id] = tenant.name;
        }
      }
      let privateLotUser = await api.getAllPrivateLotUsers(Number(this.lotId));
      if (privateLotUser) {
        this.privateLotUser.data = privateLotUser;
        this.privateLotUser.total = privateLotUser.length;
      }
      this.isLoading = false;
    },
    editTenant(tenant: Tenant) {
      this.editTenantData = tenant;
      this.tenants.showTenantForm = true;
    },
    deleteTenant(tenant: Tenant) {
      this.editTenantData = tenant;
      this.showTenantDeleteDialog = true;
    },
    closeTenantDeleteDialog() {
      this.editTenantData = null;
      this.showTenantDeleteDialog = false;
    },
    async deleteSelectedTenant() {
      this.isLoading = true;
      if (this.editTenantData) {
        let deleted = await api.deleteTenant(this.editTenantData.id);
        this.isLoading = false;
        this.closeTenantDeleteDialog();

        if (deleted) {
          this.getAllData();
        } else {
          this.$dialog.message.error(
            "Error, delete unsuccessful. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
    },
    closeTenantForm() {
      this.editTenantData = null;
      this.tenants.showTenantForm = false;
    },
    editPrivateLotUser(user: PrivateLotUser) {
      this.editUserData = user;
      this.privateLotUser.showUserForm = true;
    },
    deletePrivateLotUser(user: PrivateLotUser) {
      this.editUserData = user;
      this.showUserDeleteDialog = true;
    },
    closeUserDeleteDialog() {
      this.editUserData = null;
      this.showUserDeleteDialog = false;
    },
    async deleteSelectedUser() {
      this.isLoading = true;
      if (this.editUserData) {
        let deleted = await api.deletePrivateLotUser(this.editUserData.id);
        this.isLoading = false;
        this.closeUserDeleteDialog();

        if (deleted) {
          this.getAllData();
        } else {
          this.$dialog.message.error(
            "Error, delete unsuccessful. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
    },
    closeUserForm() {
      this.editUserData = null;
      this.privateLotUser.showUserForm = false;
    },
  },

  watch: {
    "privateLotUser.showUserForm"(value) {
      if (!value) {
        this.closeUserForm();
      }
    },
  },
});
