var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align-self":"baseline"}},[(_vm.lotId)?_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems.slice(1),"divider":"/"}}):_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems,"divider":"/"}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"baseline"}},[_c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":_vm.closeDetailsForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Add Schedule")])]}}]),model:{value:(_vm.showScheduleForm),callback:function ($$v) {_vm.showScheduleForm=$$v},expression:"showScheduleForm"}},[_c('schedule-form',{attrs:{"lot-id":_vm.lotId,"existingSchedule":_vm.schedules.selected,"needsInit":_vm.showScheduleForm},on:{"close-form":_vm.closeDetailsForm,"refresh-data":_vm.initData}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.schedules.headers,"items":_vm.schedules.data,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.task_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("taskTypeLabel")(item.task_type)))])]}},{key:"item.start_schedule_crontab",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("convert24HourToAmPm")(_vm._f("convertCrontabToTime")(item.start_schedule_crontab))))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatLocalTimestamp")(item.updated_at)))])]}},{key:"item.end_schedule_crontab",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("convert24HourToAmPm")(_vm._f("convertCrontabToTime")(item.end_schedule_crontab))))])]}},{key:"item.is_enabled",fn:function(ref){
var item = ref.item;
return [(item.is_enabled)?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1):_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",on:{"click":function($event){return _vm.openScheduleDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Schedule")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openScheduleDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Schedule")])])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.showScheduleDeleteDialog),callback:function ($$v) {_vm.showScheduleDeleteDialog=$$v},expression:"showScheduleDeleteDialog"}},[(_vm.schedules.selected)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Delete Schedule ID "+_vm._s(_vm.schedules.selected.id))]),_c('v-card-text',[_vm._v("Are you sure you would like to delete this schedule?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeScheduleDeleteDialog}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.deleteSelectedSchedule}},[_vm._v("Delete")])],1)],1):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }