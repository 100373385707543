





































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import ScheduleForm from "@/components/forms/ScheduleForm.vue";
import { ScheduledTask, SCHEDULED_TASK_TYPES } from "@/api/models";
import api from "@/api/api";
import {
  convertCrontabToTime,
  convertTimeToCrontab,
  convert24HourToAmPm,
} from "@/libs/dateUtils";

export default Vue.extend({
  name: "Schedules",

  components: {
    ScheduleForm,
  },

  data: () => ({
    lotId: 0,
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Schedules",
        disabled: true,
      },
    ],

    isLoading: false,
    showScheduleForm: false,
    showScheduleDeleteDialog: false,

    schedules: {
      headers: [
        { text: "ID", value: "id" },
        { text: "Parking Lot ID", value: "parking_lot_id" },
        { text: "Task Type", value: "task_type" },
        { text: "Description", value: "description" },
        { text: "Start At", value: "start_schedule_crontab" },
        { text: "End At", value: "end_schedule_crontab" },
        { text: "Is Enabled", value: "is_enabled" },
        { text: "Last Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      data: [] as Array<ScheduledTask>,
      selected: null as ScheduledTask | null,
    },
  }),

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  async mounted() {
    if (this.getCurrentUserData && !this.isSuperAdmin) {
      if (this.lotId) {
        this.$router.push({ name: "LotDashboard" });
      } else {
        this.$router.push({ name: "Home" });
        // window.open(
        //   `${process.env.VUE_APP_3_BASE_URL_PATH}/adminnext/dashboard`,
        //   "_self"
        // );
      }
    }
    this.getLotData(this.lotId);
    await this.initData();
  },

  filters: {
    convertCrontabToTime,
    convertTimeToCrontab,
    convert24HourToAmPm,
    taskTypeLabel(value: string): string {
      const foundTaskType = SCHEDULED_TASK_TYPES.find((t) => t.value === value);
      if (foundTaskType) {
        return foundTaskType.label;
      } else {
        return value;
      }
    },
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),
  },

  watch: {
    getCurrentUserData(value) {
      if (value && !this.isSuperAdmin) {
        if (this.lotId) {
          this.$router.push({ name: "LotDashboard" });
        } else {
          this.$router.push({ name: "Home" });
          // window.open(
          //   `${process.env.VUE_APP_3_BASE_URL_PATH}/adminnext/dashboard`,
          //   "_self"
          // );
        }
      }
    },
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);
      }
    },

    async initData() {
      try {
        this.isLoading = true;
        let scheduledTasksData = await api.getAllScheduledTasks();
        if (scheduledTasksData) {
          if (this.lotId) {
            scheduledTasksData = scheduledTasksData.filter(
              (task) => task.parking_lot_id == this.lotId
            );
          }
          this.schedules.data = scheduledTasksData;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load schedules. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },

    openScheduleDetails(schedule: ScheduledTask) {
      console.log("Opening scheduled task details", schedule);
      this.schedules.selected = schedule;
      this.showScheduleForm = true;
    },

    closeDetailsForm() {
      this.showScheduleForm = false;
      this.schedules.selected = null;
    },

    openScheduleDeleteDialog(schedule: ScheduledTask) {
      this.schedules.selected = schedule;
      this.showScheduleDeleteDialog = true;
    },

    closeScheduleDeleteDialog() {
      this.schedules.selected = null;
      this.showScheduleDeleteDialog = false;
    },

    async deleteSelectedSchedule() {
      if (!this.schedules.selected) {
        return;
      }
      this.isLoading = true;
      let deleted = await api.deleteScheduledTask(this.schedules.selected.id);
      this.isLoading = false;
      this.closeScheduleDeleteDialog();

      if (deleted) {
        this.initData(); // Refresh data
      } else {
        this.$dialog.message.error(
          "Error, delete unsuccessful. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
  },
});
